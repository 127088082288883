import { Component, DestroyRef, inject, input, model, OnInit, output, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { ButtonAddComponent } from "@app/shared/components/button/button-add/button-add.component";
import {
  confirmationDefaultPaymentText,
  confirmationDeletePaymentText,
} from "@app/shared/components/payment/shared/payment.const";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { RoamIconComponent } from "@app/shared/components/roam-icon/roam-icon.component";
import { TextLabelDescComponent } from "@app/shared/components/roam-layout/text-label-desc/text-label-desc.component";
import { PaymentMethod } from "@app/shared/interfaces/payment-method-list.interface";
import { PaymentMethodService } from "@app/shared/services/payment-method.service";
@Component({
  selector: "payment-method-list",
  standalone: true,
  imports: [RoamIconComponent, TextLabelDescComponent, ButtonAddComponent],
  template: `
    <div class="payment-list-container roam-scrollbar">
    <table>
      <tbody>
        @for (payment of payments(); track payment.id) {
          <tr>
            <td>
              <div class="align-center gap-16">
                <div class="bg-icon">
                  <roam-icon
                    [src]="false"
                    [name]="'payment/' + payment.brand"
                    color="primary" 
                    size="30"/>
                </div>
                <app-text-label-desc
                  [label]="payment.brand"
                  [desc]="'••••' + payment.last4 + ' | ' + payment.expMonth +'/' +payment.expYear"
                  [black]="true"
                  classLabel="capitalized"
                  classDesc="tc-black fw-500" />
              </div>
            </td>
            <td>
              <span class="fw-500">
                {{ payment.cardholderName }}
              </span>
            </td>
            <td>
              <div>
                <span class="font-14px" [class.default]="payment.isDefault">
                  @if (payment.isDefault) {
                    <span>Default</span>
                  } @else {
                    <span
                      class="fw-500 c-pointer text-underline"
                      (click)="makeDefault(payment.id)">
                      Make Default
                    </span>
                  }
                </span>
              </div>
            </td>
            <td>
              <div class="align-center justify-end gap-16">
                <button
                  class="btn-base tc-warning text-underline"
                  (click)="deletePaymentMethod(payment.id)">
                  Remove
                </button>
              </div>
            </td>
          </tr>
        }
      
      </tbody>
    </table>

    </div>
    <div class="mt-24">
      <app-button-add label="Link a Card or Bank" (onAdd)="addPayment.emit()" />
    </div>
  `,
  styles: `
  .payment-list-container {
    max-height: calc(90vh - 195px);
    margin-left: -1.5rem;
    padding: 0 2rem;
    display: block;
    overflow-y: auto;
  }
    table {
      width: 100%;

      tr td:first-child {
        padding-left: 0;
      }

      tr td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e9e9e9;
      }
    }
    .bg-icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: rgba(145, 70, 106, 0.1);
    }

    .default {
      display: inline-flex;
      border-radius: 4px;
      background: #876486;
      color: #fff;
      font-weight: 600;
      padding: 2px 6px;
    }
  `,
})
export class PaymentMethodListComponent {
  readonly isLoading = input<boolean>();
  payments = model<PaymentMethod[]>([]);
  addPayment = output();
  deleteList = output<string>();
  destroyRef = inject(DestroyRef);
  dialog = inject(MatDialog);
  service = inject(PaymentMethodService);

  makeDefault(id: string): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        ...dialogConfig.confirmDialogCenter,
        data: {
          confirmation: confirmationDefaultPaymentText,
        },
      })
      .afterClosed()
      .subscribe(isUpdate => {
        if (isUpdate) {
          this.updateStatus(id);
        }
      });
  }

  private updateStatus(id: string): void {
    this.service.setPaymentDefault(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.payments.update(methods => {
            return methods.map(method => {
              if (method.id === id) {
                return { ...method, isDefault: result.success };
              }
              return { ...method, isDefault: false };
            });
          });
        },
      });
  }

  deletePaymentMethod(id: string): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        ...dialogConfig.confirmDialogCenter,
        data: {
          confirmation: confirmationDeletePaymentText,
        },
      })
      .afterClosed()
      .subscribe(isDelete => {
        if (isDelete) {
          this.deleteList.emit(id);
        }
      });
  }
}
