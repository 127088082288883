import { AccountProfileEditComponent } from "@app/pages/account-profile/components/profile/account-profile-edit/account-profile-edit.component";
import { AccountLoginComponent } from "@app/pages/account-profile/components/profile/account-login/account-login.component";
import { AccountProfileInformationComponent } from "@app/pages/account-profile/components/profile/account-profile-information/account-profile-information.component";
import { AccountProfileAddressComponent } from "@app/pages/account-profile/components/profile/account-profile-address/account-profile-address.component";

export const EDIT_PROFILE_COMPONENTS = [
  AccountProfileEditComponent,
  AccountProfileInformationComponent,
  AccountProfileAddressComponent,
  AccountLoginComponent,
];

export const EDIT_ACCOUNT_COMPONENTS = [
  [...EDIT_PROFILE_COMPONENTS]
]
