import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  output,
  signal,
} from "@angular/core";
import { PaymentEmptyStateComponent } from "../../components/account-payment/payment-empty-state/payment-empty-state.component";
import { MatDialog } from "@angular/material/dialog";
import { AddPaymentMethodComponent } from "@app/shared/components/payment/add-payment-method/add-payment-method.component";
import { PaymentModule } from "@app/shared/components/payment/payment.module";
import { dialogConfig } from "@app/core/const/dialog.const";
import { PaymentMethodListComponent } from "../../components/account-payment/payment-method-list/payment-method-list.component";
import { PaymentMethodService } from "@app/shared/services/payment-method.service";
import { PaymentMethod } from "@app/shared/interfaces/payment-method-list.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { share } from "rxjs";
import { LlcStripeDialogComponent } from "@app/shared/components/roam-dialog/llc-stripe-dialog/llc-stripe-dialog.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "account-payment",
  standalone: true,
  imports: [
    PaymentEmptyStateComponent,
    PaymentMethodListComponent,
    PaymentModule,
    MatProgressSpinnerModule,
  ],
  template: `
    <div>
      @if (!isLoading()) {
        @if (paymentMethods().length === 0) {
          <payment-empty-state (addPayment)="addPayment()" />
        } @else {
          <payment-method-list
            [payments]="paymentMethods()"
            (addPayment)="addPayment()"
            (deleteList)="deleteList($event)"
          />
        }
      } @else {
        <div class="justify-center">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate"
            diameter="30" />
        </div>
      }
    </div>
  `,
  styles: `
    :host {
      position: relative;
      height: calc(90vh - 124px);
      overflow-y: auto;
      display: block;
      overflow-x: hidden;
      margin-right: -2rem;
    }
  `,
})
export class AccountPaymentComponent implements OnInit {
  showButton = output<boolean>();
  paymentMethods = signal<PaymentMethod[]>([]);
  isLoading = signal<boolean>(false);

  destroyRef = inject(DestroyRef);
  dialog = inject(MatDialog);
  service = inject(PaymentMethodService);

  ngOnInit(): void {
    this.getPaymentMethods();
    this.showButton.emit(false);
  }

  private getPaymentMethods(): void {
    this.isLoading.set(true);
    this.service
      .paymentMethod()
      .pipe(takeUntilDestroyed(this.destroyRef), share())
      .subscribe({
        next: resp => {
          this.paymentMethods.set(resp);
          this.isLoading.set(false);
        },
      });
  }

  addPayment(): void {
    this.dialog
      .open(LlcStripeDialogComponent, {
        ...dialogConfig.dialogStd,
      })
      .afterClosed()
      .subscribe(state => {
        if (state) {
          this.openPaymentMethodDialog();
        }
      });
  }

  openPaymentMethodDialog(): void {
    this.dialog
      .open(AddPaymentMethodComponent, {
        ...dialogConfig.addDialog,
        maxWidth: "700px",
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.getPaymentMethods();
        }
      });
  }

  deleteList(id: string): void {
    this.service
      .deletePaymentMethod(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: resp => {
          this.paymentMethods.update(methods => {
            return methods.filter(method => method.id !== id);
          });
        },
      });
  }
}
