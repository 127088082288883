import { Component, output } from '@angular/core';
import { ButtonAddComponent } from '@app/shared/components/button/button-add/button-add.component';
import { RoamIconComponent } from '@app/shared/components/roam-icon/roam-icon.component';

@Component({
  selector: 'payment-empty-state',
  standalone: true,
  imports: [RoamIconComponent, ButtonAddComponent],
  template: `
    <div class="justify-center f-column">
      
      <roam-icon [src]="false" name="credit-card-key"/>
      
      <div class="justify-center f-column mt-24">
        <h3 class="font-20px tc-black fw-500">
          No Payment Method Added
        </h3>
        <p class="tc-grey mb-24">
          Oops! It seems like you haven't added a payment method yet.
        </p>
        <app-button-add 
          label="Link a Card or Bank"
          (onAdd)="addPayment.emit()"
        />
      </div>
    </div>
  `,
  styles: ``
})
export class PaymentEmptyStateComponent {
  addPayment = output()
}
