import { Component, Inject, OnInit, signal } from "@angular/core";
import { IModule } from "@app/shared/interfaces";
import { accountEditMenu, accountMenu } from "@app/pages/account-profile/shared/const/account-edit-menu.const";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {

  public menus: IModule[] = accountEditMenu;
  public index: number = 0;
  public render = accountMenu;
  public renderTemplate: string = this.render.PROFILE;
  public sectionName: string = 'Your Profile';
  readonly showButtonSave = signal<boolean>(true);

  constructor(
    private dialogRef: MatDialogRef<AccountEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {index: number, path: string, tab: string, section: string}) {

    this.renderTemplate = data.path;
    this.sectionName = data.section;
  }

  ngOnInit(): void {
    this.index = this.data.index || 0;
  }

  public switchMenu(index: number): void {
    this.index = index;
    this.renderTemplate = this.menus[this.index].id as string;
  }

   public actionDialog(): void {
     this.dialogRef.close()
   }
}
